import React, { useEffect, useState } from "react";
import SideBar from "./sidebar/SideBar";
import "./sidebar.css";

const Layout = ({ children }) => {
  const [isNavBtnClicked, setIsNavBtnClicked] = useState(false);
  const [isMenuCollapse, setIsMenuCollapse] = useState(false);
  console.log("is menu", isMenuCollapse);
  const [isMobile, setIsMobile] = useState(false);

  // console.log('isMobile',isMobile)

  useEffect(() => {
    // console.log('size lisened')
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const menuClick = () => {
    setIsNavBtnClicked(true);
    setIsMenuCollapse(true);
  };

  const menuCancel = () => {
    setIsNavBtnClicked(false);
    setIsMenuCollapse(false);
  };

  const toggleMenuCollapse = () => {
    setIsMenuCollapse(!isMenuCollapse);
  };

  return (
    <>
      {!isMobile ? (
        <div className="">
          <div
            className="d-flex col-lg-12"
            style={{ width: "100%", height: "100vh", overflow: "hidden" }}
          >
            <div
              className={`${isMenuCollapse ? "" : ""} `}
              //style={{ height: "99vh", overflow: "auto", overflowX: "hidden" }}
            >
              <SideBar
                isMenuCollapse={isMenuCollapse}
                toggleMenuCollapse={toggleMenuCollapse}
                isNavBtnClicked={isNavBtnClicked}
                menuCancel={menuCancel}
              />
            </div>

            <div className={`${isMenuCollapse ? "w-100" : "w-100"}`} style={{backgroundColor:'#e9ecef'}}>
              {children}
            </div>
          </div>
        </div>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
};

export default Layout;
