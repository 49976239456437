import { useRef, useEffect, useState, React } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  Card,
  Stack,
  Button,
} from "react-bootstrap";
import "../css/invoice.css";
import { addExpires, formateDate } from "../../shared/helper/dateConvertion";
import { useSelector } from "react-redux";
import { ToWords } from "to-words";
import logo from "../../../assets/bill.png";
import { taxList } from "../../shared/helper/taxConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPrint } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";

const InvoiceBody = (props) => {
  const { data, pdfRef, handleGoBack, handlePrint } = props;
  const pdf = useRef();
  const { business } = useSelector((state) => state.auth);
  const { getSubscriptionData, getSubscriptionStatus, selectId } = useSelector(
    (state) => state.subscription
  );

  console.log("invoice data get", data);
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options);
  };

  return (
    <Container className="py-2  " fluid>
      <Card
        style={{
          backgroundColor: "white",
          border: "1px solid lightgray",
          borderRadius: "0px",
          height: "calc(100vh - 75px)",
        }}
      >
        <Row className="py-3">
          <Col lg={6} className="px-4 ">
            <FontAwesomeIcon className="cursor-pointer"
              icon={faChevronLeft}
              style={{ fontSize: "13px", cursor: 'pointer' }}
              onClick={handleGoBack}
            />
            <Form.Label className="mb-0 mx-2" style={{ fontSize: "14px" }}>
              {"#" + data[0]?.subscription_vouchers?.[0]?.voucher?.voucher_number}
            </Form.Label>
          </Col>
          <Col lg={6}>
            <Stack direction="horizontal" className="float-end mx-2">
              <Button variant="outline-secondary " onClick={handlePrint}>
                <FontAwesomeIcon
                  className="mx-2"
                  icon={faPrint}
                  style={{ fontSize: "13px" }}
                />{" "}
                Print
              </Button>
            </Stack>
          </Col>
        </Row>
        <Row
          className="py-3 mx-2 bg"
          style={{
            height: "calc(100vh - 80px)",
            overflow: "auto",
            overflowX: "hidden",
            backgroundColor: "rgb(242,242,242)",
            borderRadius: "5px",
          }}
        >
          <Col md={2}></Col>
          <Col md={8} className="bg-white py-2" style={{ boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)' }}>
            <div ref={pdfRef}>
              <div className="d-flex align-items-center mb-2">
                <Col className="py-1" xs={1}>
                  <Form.Label
                    className="mb-0"
                    style={{ fontSize: "11px", fontWeight: 600 }}
                  >
                    {"TAX INVOICE"}
                  </Form.Label>
                </Col>
                <Col xs={3} className="py-0 p-0">
                  <div className="border px-2">
                    <Form.Label
                      className="mb-0 p-0 m-0"
                      style={{ fontSize: "11px", fontWeight: 400 }}
                    >
                      {"ORIGINAL FOR RECIPIENT"}
                    </Form.Label>
                  </div>
                </Col>
              </div>
              <div className="border bg-white">
                <div
                  className="d-flex  px-2"
                  style={{
                    // borderTop: "1px solid lightgray",
                    borderBottom: "1px solid lightgray",
                  }}
                >
                  <Col xs={6} className="p-2">
                    <img
                      src={require("../../../assets/bill.png")}
                      width={120}
                      height={60}
                      alt="Logo"
                      className=" mt-6"
                    />
                  </Col>
                  <Col
                    xs={6}
                    className="p-2"
                    style={{ borderLeft: "1px solid lightgray" }}
                  >
                    <div class="d-flex justify-content-between col-12">
                      <div>
                        <Form.Label
                          className="mb-0"
                          style={{ fontSize: "14px", fontWeight: 500 }}
                        >
                          {business?.business_name}
                        </Form.Label>
                        <br />
                        <Form.Label
                          className="mb-0"
                          style={{ fontSize: "12px", fontWeight: 400 }}
                        >
                          {business?.business_address}
                        </Form.Label>
                      </div>
                      <div>
                        <Form.Label
                          className="mb-0 form-label"
                          style={{ fontSize: "14px", fontWeight: 500 }} >
                          {"Subscription No"}
                        </Form.Label>
                        <br />
                        <Form.Label style={{ fontSize: "12px" }}>
                        {data[0]?.subscription_vouchers?.[0]?.subscription?.subscription_number || "N/A"}
                        </Form.Label> </div>
                    </div>
                  </Col>
                </div>

                <div className="d-flex justify-content-between  py-1 px-3">
                  <div className="">
                    <Form.Label
                      className="mb-0"
                      style={{ fontSize: "13px", fontWeight: 500 }}
                    >
                      {"Invoice No"}
                    </Form.Label>
                    <br />
                    <Form.Label className="mb-0" style={{ fontSize: "12px" }}>
                      {data[0]?.subscription_vouchers?.[0]?.voucher?.voucher_number}
                    </Form.Label>
                  </div>
                  <div className="">
                    <Form.Label
                      className="mb-0"
                      style={{ fontSize: "13px", fontWeight: 500 }}
                    >
                      {"Invoice Date"}
                    </Form.Label>
                    <br />
                    <Form.Label className="mb-0" style={{ fontSize: "12px" }}>
                      {formatDate(data[0]?.start_date)}
                    </Form.Label>
                  </div>
                  <div className="">
                    <Form.Label
                      className="mb-0"
                      style={{ fontSize: "13px", fontWeight: 500 }}
                    >
                      {"Next Bill Date"}
                    </Form.Label>
                    <br />
                    <Form.Label className="mb-0" style={{ fontSize: "12px" }}>
                      {formatDate(data[0]?.bill_queue?.next_bill_date)}
                    </Form.Label>
                  </div>
                </div>
                <div
                  className="d-flex px-3"
                  style={{ borderTop: "1px solid lightgray" }}
                >
                  <Col
                    xs={6}
                    className=" py-1"
                    style={{ fontSize: "13px", fontWeight: 500 }}
                  >
                    <Form.Label className="mb-0">{"BILL TO"}</Form.Label>
                  </Col>
                  <Col
                    xs={6}
                    className="px-2 py-1"
                    style={{
                      fontSize: "13px",
                      fontWeight: 500,
                      borderLeft: "1px solid lightgray",
                    }}
                  >
                    <Form.Label className="mb-0">{"SHIP TO"}</Form.Label>
                  </Col>
                </div>
                <div className="d-flex px-3">
                  <Col
                    xs={6}
                    className=""
                    style={{ fontSize: "12px", fontWeight: 500 }}
                  >
                    <Form.Label className="mb-0">
                      {" "}
                      {data[0]?.party?.name}
                    </Form.Label>
                  </Col>
                  <Col
                    xs={6}
                    className="px-2"
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                      borderLeft: "1px solid lightgray",
                    }}
                  >
                    <Form.Label className="mb-0">
                      {data[0]?.party?.name}
                    </Form.Label>
                  </Col>
                </div>
                <div className="d-flex px-3">
                  {data?.party?.party_addresses[0] && (
                    <Col xs={6} className="">
                      <Form.Label className="mb-0">
                        {data[0]?.party?.party_addresses[0]}
                      </Form.Label>
                    </Col>
                  )}
                  {data?.party?.party_addresses?.label && (
                    <Col
                      xs={6}
                      className="px-2"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >
                      <Form.Label className="mb-0">
                        {" "}
                        {data[0]?.party?.party_addresses?.address}
                      </Form.Label>
                    </Col>
                  )}
                </div>
                <div className="d-flex px-3">
                  <Col
                    xs={6}
                    className=""
                    style={{ fontSize: "12px", fontWeight: 500 }}
                  >
                    <Form.Label className="mb-0">
                      {" "}
                      {data[0]?.party?.phone_number}
                    </Form.Label>
                  </Col>
                  <Col
                    xs={6}
                    className="px-2"
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                      borderLeft: "1px solid lightgray",
                    }}
                  >
                    <Form.Label className="mb-0">
                      {data[0]?.party?.phone_number}
                    </Form.Label>
                  </Col>
                </div>
                <div className="d-flex px-3">
                  {data[0]?.party?.gstin && (
                    <Col
                      xs={6}
                      className=""
                      style={{ fontSize: "12px", fontWeight: 500 }}
                    >
                      <Form.Label className="mb-0">
                        {" "}
                        {data[0]?.party?.gstin}
                      </Form.Label>
                    </Col>
                  )}
                  {data[0]?.party?.pan && (
                    <Col
                      xs={6}
                      className="px-2"
                      style={{
                        fontSize: "12px",
                        fontWeight: 500,
                        borderLeft: "1px solid lightgray",
                      }}
                    >
                      <Form.Label className="mb-0">
                        {data[0]?.party?.gstin}
                      </Form.Label>
                    </Col>
                  )}
                </div>
                <div className="d-flex px-3 " style={{ borderBottom: '1px solid lightgray' }}>
                  {data[0]?.party?.gstin && (
                    <Col
                      xs={6}
                      className=""
                      style={{ fontSize: "12px", fontWeight: 500 }}
                    >
                      <Form.Label className="mb-0">
                        {" "}
                        {data[0]?.party?.pan}
                      </Form.Label>
                    </Col>
                  )}
                  {data[0]?.party?.pan && (
                    <Col
                      xs={6}
                      className="px-2"
                      style={{
                        fontSize: "12px",
                        fontWeight: 500,
                        borderLeft: "1px solid lightgray",
                      }}
                    >
                      <Form.Label className="mb-0">
                        {data[0]?.party?.pan}
                      </Form.Label>
                    </Col>
                  )}
                </div>
                <div className="">
                  <Col xs={12} className="">
                    <Table responsive>
                      <thead>
                        <tr
                          className="px-2 "
                          style={{
                            fontSize: "13px",
                            fontWeight: 500,
                            color: "#4682B4",
                            // borderTop: "1px solid lightgray",
                            textAlign: "center",
                          }}
                        >
                          <th
                            style={{
                              color: "#4682B4",
                              borderRight: "1px solid lightgray",
                            }}
                          >
                            S.NO
                          </th>
                          <th
                            style={{
                              color: "#4682B4",
                              borderRight: "1px solid lightgray",
                              width: "100px",
                            }}
                          >
                            ITEMS
                          </th>
                          <th
                            style={{
                              color: "#4682B4",
                              borderRight: "1px solid lightgray",
                            }}
                          >
                            Code
                          </th>
                          <th
                            style={{
                              color: "#4682B4",
                              borderRight: "1px solid lightgray",
                            }}
                          >
                            QTY
                          </th>
                          <th
                            style={{
                              color: "#4682B4",
                              borderRight: "1px solid lightgray",
                            }}
                          >
                            RATE
                          </th>
                          <th
                            style={{
                              color: "#4682B4",
                              borderRight: "1px solid lightgray",
                            }}
                          >
                            TAX
                          </th>
                          <th style={{ color: "#4682B4" }}>AMOUNT</th>
                        </tr>
                      </thead>
                      {data &&
                        data?.length > 0 &&
                        data?.map((item, index) => {
                          return (
                            <tbody>
                              <tr
                                style={{
                                  fontSize: "13px",
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                >
                                  {" "}
                                  {index + 1}
                                </td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                >
                                  {" "}
                                  {item?.subscription_items[0]?.product?.name}
                                </td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                >
                                  {" "}
                                  {item?.subscription_items[0]?.product_code}
                                </td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                >
                                  {" "}
                                  {item?.subscription_items[0]?.quantity}
                                </td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                >
                                  ₹{item?.subscription_items[0]?.price}
                                </td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                >
                                  ₹{" "}
                                  {item?.subscription_vouchers[0]?.subscription?.tax_total}
                                  <span style={{ color: "#808080" }}>
                                    (
                                    {
                                      item?.subscription_vouchers[0]?.voucher
                                        ?.voucher_items[0]?.tax_rate
                                    }
                                    %)
                                  </span>
                                </td>
                                <td style={{ borderBottom: "none" }}>
                                  ₹{" "}
                                  {item?.grand_total}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td style={{ borderBottom: "none" }}></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td style={{ borderBottom: "none" }}></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td style={{ borderBottom: "none" }}></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td style={{ borderBottom: "none" }}></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td style={{ borderBottom: "none" }}></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td style={{ borderBottom: "none" }}></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td style={{ borderBottom: "none" }}></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td style={{ borderBottom: "none" }}></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderBottom: "none",
                                    borderRight: "1px solid lightgray",
                                  }}
                                ></td>
                                <td style={{ borderBottom: "none" }}></td>
                              </tr>
                              <tr
                                style={{
                                  fontSize: "13px",
                                  textAlign: "right",

                                  fontWeight: 500,
                                }}
                              >
                                <td
                                  style={{
                                    borderRight: "1px solid lightgray",
                                    backgroundColor: "rgb(248, 249, 250)",
                                    borderTop: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderRight: "1px solid lightgray",
                                    backgroundColor: "rgb(248, 249, 250)",
                                    borderTop: "1px solid lightgray",
                                  }}
                                >
                                  Total
                                </td>
                                <td
                                  style={{
                                    borderRight: "1px solid lightgray",
                                    backgroundColor: "rgb(248, 249, 250)",
                                    borderTop: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderRight: "1px solid lightgray",
                                    backgroundColor: "rgb(248, 249, 250)",
                                    borderTop: "1px solid lightgray",
                                  }}
                                >
                                  {item?.subscription_items[0]?.quantity}
                                </td>
                                <td
                                  style={{
                                    borderRight: "1px solid lightgray",
                                    backgroundColor: "rgb(248, 249, 250)",
                                    borderTop: "1px solid lightgray",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    borderRight: "1px solid lightgray",
                                    textAlign: "center",
                                    backgroundColor: "rgb(248, 249, 250)",
                                    borderTop: "1px solid lightgray",
                                  }}
                                >
                                  {
                                    item?.subscription_vouchers[0]?.voucher
                                      ?.voucher_items[0]?.tax_rate
                                  }
                                </td>
                                <td
                                  align="center"
                                  style={{
                                    backgroundColor: "rgb(248, 249, 250)",
                                    borderTop: "1px solid lightgray",
                                  }}
                                >
                                  ₹{item?.grand_total}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontSize: "13px",
                                  textAlign: "right",
                                  fontWeight: 500,
                                }}
                              >
                                <td
                                  style={{ borderRight: "1px solid lightgray" }}
                                ></td>
                                <td
                                  style={{ borderRight: "1px solid lightgray" }}
                                >
                                  Received Amount
                                </td>
                                <td
                                  style={{ borderRight: "1px solid lightgray" }}
                                ></td>
                                <td
                                  style={{ borderRight: "1px solid lightgray" }}
                                ></td>
                                <td
                                  style={{ borderRight: "1px solid lightgray" }}
                                ></td>
                                <td
                                  style={{ borderRight: "1px solid lightgray" }}
                                ></td>
                                <td align="center">
                                  ₹{item?.paid_amount ? item?.paid_amount : "0.00"}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontSize: "13px",
                                  textAlign: "right",
                                  fontWeight: 500,
                                }}
                              >
                                <td
                                  style={{ borderRight: "1px solid lightgray" }}
                                ></td>
                                <td
                                  style={{ borderRight: "1px solid lightgray" }}
                                >
                                  Balance Amount
                                </td>
                                <td
                                  style={{ borderRight: "1px solid lightgray" }}
                                ></td>
                                <td
                                  style={{ borderRight: "1px solid lightgray" }}
                                ></td>
                                <td
                                  style={{ borderRight: "1px solid lightgray" }}
                                ></td>
                                <td
                                  style={{ borderRight: "1px solid lightgray" }}
                                ></td>
                                <td align="center">
                                  ₹{item?.grand_total - Number(item?.paid_amount)
                                    ? item?.grand_total -
                                    Number(item?.paid_amount)
                                    : "0.00"}
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </Table>
                  </Col>
                </div>

                <div className="" style={{ borderBottom: "1px solid lightgray", borderTop: "1px solid lightgray", }}>
                  <Col xs={12}>
                    <Table responsive>
                      <thead>
                        <tr
                          style={{

                            fontSize: "13px",
                            textAlign: "center",
                            fontWeight: 500,
                          }}
                        >
                          <th
                            rowSpan={2}
                            style={{
                              color: "#4682B4",
                              borderRight: "1px solid lightgray",
                            }}
                          >
                            HSN/SAC
                          </th>
                          <th
                            rowSpan={2}
                            style={{
                              color: "#4682B4",
                              borderRight: "1px solid lightgray",
                            }}
                          >
                            TAXABLE VALUE
                          </th>
                          <th
                            colSpan={2}
                            style={{
                              color: "#4682B4",
                              borderRight: "1px solid lightgray",
                            }}
                          >
                            CGST
                          </th>
                          <th
                            colSpan={2}
                            style={{
                              color: "#4682B4",
                              borderRight: "1px solid lightgray",
                            }}
                          >
                            SGST
                          </th>
                          <th
                            rowSpan={2}
                            style={{
                              color: "#4682B4",
                            }}
                          >
                            TOTAL TAX AMOUNT
                          </th>
                        </tr>
                        <tr
                          style={{
                            fontSize: "11px",
                            textAlign: "center",
                            fontWeight: 500,
                          }}
                        >
                          <th
                            style={{
                              color: "#4682B4",
                              borderRight: "1px solid lightgray",
                            }}
                            className=""
                          >
                            RATE
                          </th>
                          <th
                            style={{
                              color: "#4682B4",
                              borderRight: "1px solid lightgray",
                            }}
                            className=""
                          >
                            AMOUNT
                          </th>
                          <th
                            style={{
                              color: "#4682B4",
                              borderRight: "1px solid lightgray",
                            }}
                            className=""
                          >
                            RATE
                          </th>
                          <th
                            style={{
                              color: "#4682B4",
                              borderRight: "1px solid lightgray",
                            }}
                            className=""
                          >
                            AMOUNT
                          </th>
                        </tr>
                      </thead>
                      {data &&
                        data[0]?.subscription_vouchers &&
                        data[0]?.subscription_vouchers.length > 0 &&
                        data[0]?.subscription_vouchers.map((item, index) => {
                          return (
                            <tbody>
                              <tr
                                key={index}
                                style={{ fontSize: "13px", textAlign: "center" }}
                              >
                                <td
                                  style={{
                                    borderRight: "1px solid lightgray",
                                  }}
                                >
                                  {item?.voucher?.voucher_items[0]?.hsn
                                    ? item?.voucher.voucher_items[0]?.hsn
                                    : "-"}
                                </td>
                                <td
                                  style={{
                                    borderRight: "1px solid lightgray",
                                  }}
                                >
                                  ₹{item?.voucher?.taxable_value}
                                </td>
                                <td
                                  style={{
                                    borderRight: "1px solid lightgray",
                                  }}
                                >
                                  {item?.voucher?.voucher_items[0]?.tax_rate
                                    ? `${item?.voucher?.voucher_items[0]
                                      ?.tax_rate / 2
                                    }%`
                                    : "N/A"}
                                </td>
                                <td
                                  style={{
                                    borderRight: "1px solid lightgray",
                                  }}
                                >
                                  ₹{item?.voucher?.cgst_total}
                                </td>
                                <td
                                  style={{
                                    borderRight: "1px solid lightgray",
                                  }}
                                >
                                  {item?.voucher?.voucher_items[0]?.tax_rate
                                    ? `${item?.voucher?.voucher_items[0]
                                      ?.tax_rate / 2
                                    }%`
                                    : "N/A"}
                                </td>
                                <td
                                  style={{
                                    borderRight: "1px solid lightgray",
                                  }}
                                >
                                  ₹{item?.voucher?.sgst_total}
                                </td>
                                <td>₹{item?.voucher?.tax_total}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </Table>
                  </Col>
                </div>
                <div className="d-flex">
                  <Col className="px-2" xs={6}>
                    <Form.Label
                      className="mb-0"
                      style={{ fontSize: "13px", fontWeight: 600 }}
                    >
                      Terms And Condtions
                    </Form.Label>
                    <br />
                    <Form.Label className="mb-0" style={{ fontSize: "12px" }}>
                      Goods once sold not return back or exchange
                    </Form.Label>
                  </Col>
                  <Col
                    className="px-2"
                    xs={6}
                    style={{ borderLeft: "1px solid lightgray" }}
                  >
                    <Form.Label
                      className="mb-0"
                      style={{ fontSize: "13px", fontWeight: 600 }}
                    >
                      AUTHORIZED SIGNATORY FOR
                    </Form.Label>
                    <br />
                    <div className="border mb-2" style={{ height: "50px" }}></div>
                  </Col>
                </div>
              </div>
            </div>
          </Col>
          <Col md={2}></Col>
        </Row>
      </Card>
    </Container>
  );
};
export default InvoiceBody;
