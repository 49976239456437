import { faList } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NavDropdown, Navbar, Nav } from "react-bootstrap"
import { useNavigate} from "react-router-dom"
import { useDispatch} from "react-redux";
import { useState } from "react";
import LogoutModal from "./Logout";
import {
  UilHome,
  UilBell,
  UilInvoice,
  UilCreditCard,
  UilListUl,
  UilSignOutAlt,
} from "@iconscout/react-unicons";
import { resetAuth } from "../../login/store/authSlice";
import { resetSubscription } from "../../subscription/store/subscriptionSlice";
const DropDownNavigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const handleNavigate = (navigation) => {
    console.log('handle navigation click')
    navigate(`/${navigation}`)

  }
  const [show, setShow] = useState(false);

  const handleLogoutModal = () => {
    setShow(!show);
  };
  const handleLogout = () => {
    dispatch(resetAuth());
    dispatch(resetSubscription());
    navigate("/login");
  };
  return (
    //   <Form.Group controlId="formSelect">
    //   <Form.Label>Dropdown Example</Form.Label>
    //   <div className="d-flex align-items-center"> {/* Flex container for label and icon */}
    //     <FontAwesomeIcon icon={faList} className="me-2" />
    //     <Form.Select value={""} onChange={handleNavigate}>
    //       <option value="">  <FontAwesomeIcon icon={faList} className="me-2" /></option>
    //       <option value="/home">Home</option>
    //       <option value="/subscription">Subscription</option>
    //       <option value="/invoices">Invoices</option>
    //       <option value="/payment">Payments Made</option>
    //       <option value="#/action-3">Statements</option>
    //     </Form.Select>
    //   </div>
    // </Form.Group>
    <>

      <NavDropdown title={<FontAwesomeIcon icon={faList} className="me-2" />} id="navbarScrollingDropdown">

        <NavDropdown.Item onClick={() => { handleNavigate('home') }}>
          <UilHome size="18" className="me-2" />
          Home
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={() => { handleNavigate('subscription') }}>
          <UilBell size="18" className="me-2" />
          Subscription
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={() => { handleNavigate('subscription/invoices') }}>
          <UilInvoice size="18" className="me-2" />
          Invoices
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={() => { handleNavigate('subscription/payment') }}>
          <UilCreditCard size="18" className="me-2" />
          Payment
        </NavDropdown.Item>
        <NavDropdown.Divider />
        {/* <NavDropdown.Item onClick={() => { handleNavigate('subscription/statement') }}>
          <UilListUl size="18" className="me-2" />
          Statements
        </NavDropdown.Item>
        <NavDropdown.Divider /> */}
        <NavDropdown.Item onClick={handleLogoutModal}>
          <UilSignOutAlt size="18" className="me-2" color="red" />
          Logout
        </NavDropdown.Item>

      </NavDropdown>
      {show && <LogoutModal onConfirm={handleLogout} onCancel={setShow} />}
    </>
  )

}
export default DropDownNavigation