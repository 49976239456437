import Header from "../shared/Header";
import Layout from "../shared/components/Layout";
import { Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ActiveSubscription from "./components/ActiveSubscription";
import TotalDue from "./components/TotalDue";
import NextDueDate from "./components/NextDueDate";
import LastPaid from "./components/LastPaid";
import { useEffect } from "react";
import { getAllPaymentsWithPartyId, getAllSubscriptionVoucher, getSubscription } from "../subscription/store/subscriptionSlice";

const HomePage = (props) => {
  const{isMobile}=props
  const dispatch = useDispatch();
  const { party,accessToken,tenantId,errorStatus ,errorMessage} = useSelector((state) => state.auth);
  console.log('error stTUS',errorStatus,errorMessage)
  const { getSubscriptionData } = useSelector((state) => state.subscription);
  console.log('access token',accessToken)
  console.log("getSubscriptionData", getSubscriptionData);
  console.log("party", party);
  console.log('curent tenant id',tenantId)

  useEffect(() => {
    const partyId =party?.id
    dispatch(getSubscription({partyId,accessToken,tenantId}))
    dispatch(getAllSubscriptionVoucher({partyId,accessToken,tenantId}));
    dispatch(getAllPaymentsWithPartyId({partyId,accessToken,tenantId}));
  }, [party]);

  return (
    <Layout>
      <Header title={`Hello ${party?.name} ...!`} />
      <Container className="p-2" fluid>
      <div
  className={isMobile ? "" : "border p-4"}
  style={{
    height: "calc(100vh - 80px)",
    overflow: "auto",
    overflowX: "hidden",
    // backgroundColor: "white",
  }}
>
          <Row>
            <Col lg={3} className="mt-2">
              <ActiveSubscription />
            </Col>
            <Col lg={3} className="mt-2">
              <TotalDue />
            </Col>
            <Col lg={3} className="mt-2">
              <NextDueDate />
            </Col>
            <Col lg={3} className="mt-2">
              <LastPaid />
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
};
export default HomePage;
