import React from "react";
import { Col, Row, Card, Form } from "react-bootstrap";
import { UilCalender } from "@iconscout/react-unicons";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const NextDueDate = () => {
  const { getSubscriptionData } = useSelector(
    (state) => state.subscription
  );

  const nearestDate = useMemo(() => {
    if (!getSubscriptionData || getSubscriptionData.length === 0) return null;

    const today = new Date();

    const parseDate = (dateString) => {
      const date = new Date(dateString);
      return isNaN(date) ? null : date;
    };

    const datesWithDifference = getSubscriptionData
      .map((dateString) => {
        const date = parseDate(dateString?.bill_queue?.next_bill_date);
        return date ? { date, difference: Math.abs(date - today) } : null;
      })
      .filter((dateObj) => dateObj !== null);

    if (datesWithDifference.length === 0) return null;

    datesWithDifference.sort((a, b) => a.difference - b.difference);

    return datesWithDifference[0].date;
  }, [getSubscriptionData]);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formattedNearestDate = nearestDate
    ? formatDate(nearestDate)
    : "No valid date found";
  console.log("Nearest Date:", nearestDate);
  return (
    <Card
      className=""
      style={{
        backgroundColor: "white",
        boxShadow: "0 0.125rem 0.25rem rgba(6, 9, 23, 0.075)",
        border: "1px solid #dee2e6",
      }}
    >
      <Row>
        <Col lg={12} className="px-4 py-2 align-items-center">
          <UilCalender size="18" color="#FFA500" />
          <Form.Label className="mx-2" style={{ fontSize: "14px" }}>
            Next Due Date
          </Form.Label>
        </Col>
        <Col lg={12} className="px-4 py-2 align-items-center">
          <Form.Label className="mx-2 mb-0" style={{ fontSize: "14px" }}>
            {formattedNearestDate}
          </Form.Label>
        </Col>
      </Row>
    </Card>
  );
};

export default NextDueDate;
