import { Form, Stack } from "react-bootstrap";
import { UilPuzzlePiece } from "@iconscout/react-unicons";

const Addon = () => {
  return (
    <Stack
      gap={2}
      className="text-center"
      style={{ borderRight: "1px solid lightgray" }}
    >
      <span className="text-black  fw-normal px-3" style={{ fontSize: "12px" }}>
        0
      </span>
      <span
        className=" h4"
        style={{ fontSize: "12px", fontWeight: "400", color: "#6571FF" }}
      >
        {" "}
        <UilPuzzlePiece color="#6571FF" size="16px" className="mx-2" />
        <Form.Label className="mb-0">Add On</Form.Label>
      </span>
    </Stack>
  );
};

export default Addon;
