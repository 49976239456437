import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";


const LogoutModal = ({onCancel,onConfirm }) => {
  return (
    <SweetAlert
      custom
      confirmBtnBsStyle="btn btn-danger mb-3 fs-6 rounded "
      cancelBtnBsStyle="btn btn-secondary mb-3 fs-6  rounded text-white"
      confirmBtnText="Logout"
      cancelBtnText="Cancel"
      onConfirm={onConfirm}
      onCancel={onCancel}
      showCancel
      focusCancelBtn
      style={{ fontSize: '14px', width: '300px' }}
      showConfirm={true}
      
    ><div style={{ textAlign: 'center', padding: '10px' }}>
      <span className="sweet-text" style={{fontSize:'14px'}}>
        Are you sure want to Logout ?
      </span>
      </div>
    </SweetAlert>
  );
};

export default LogoutModal;
