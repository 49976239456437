import { useEffect, useState } from "react";
import Header from "../../shared/Header";
import Layout from "../../shared/components/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubscription,
  getSubscriptionVoucher,
} from "../store/subscriptionSlice";
import DataTable from "react-data-table-component";
import { Badge, Card, Col, Container, FormControl, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GlobalSearch from "./fields/GlobalSearch";
import { UilFilePlusAlt } from "@iconscout/react-unicons";
import { getLocalizedDate } from "../../shared/helper/dateUtils";

const InvoicesIndex = ({ isMobile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getSubscriptionData, getSubscriptionStatus, voucher } = useSelector(
    (state) => state.subscription
  );

  const { party, accessToken, tenantId } = useSelector((state) => state.auth);

  const subscriptionVouchers = getSubscriptionData[0]?.subscription_vouchers[0];
  console.log("subscriptionVouchers", subscriptionVouchers);
  const filterdata =
    voucher &&
    voucher.length > 0 &&
    voucher.filter((value) => value?.party_id === party?.id);
  console.log("filter data", filterdata);
  const [filteredData, setFilteredData] = useState(
    filterdata ? filterdata : []
  );
  const [searchString, setSearchString] = useState("");
  console.log("filter data", filteredData);

  useEffect(() => {
    if (searchString.length == 0) {
      setFilteredData(filterdata);
    } else {
      setFilteredData(filteredData);
    }
  }, [searchString]);

  const handleSearch = (searchParam) => {
    const lowerCaseSearchParam = searchParam.toLowerCase();

    setSearchString(searchParam);
    const filtered = filterdata.filter(
      (datum) =>
        (datum?.voucher_items[0]?.product_name &&
          datum?.voucher_items[0]?.product_name
            .toLowerCase()
            .includes(lowerCaseSearchParam)) ||
        (datum?.voucher_items[0]?.product_code &&
          datum?.voucher_items[0]?.product_code
            .toLowerCase()
            .includes(lowerCaseSearchParam))
    );

    setFilteredData(filtered);
    console.log("search param", filtered);
  };

  useEffect(() => {
    const partyId = party?.id;
    dispatch(getSubscription({ partyId, tenantId, accessToken }));
  }, []);

  const handleRowClick = (row) => {
    console.log("row", row);
    const partyId = row?.id;
    dispatch(
      getSubscriptionVoucher({
        partyId: partyId,
        tenantId: tenantId,
        accessToken: accessToken,
      })
    );
    const rowData =
      getSubscriptionData &&
      getSubscriptionData.length > 0 &&
      getSubscriptionData.filter(
        (value) => value.id === row?.voucher_subscription?.subscription_id
      );
    console.log("row data", rowData);
    navigate("/subscription/invoice", { state: rowData });
  };
  
  const dateCreatedAtBodyTemplate = (rowData) => {
    return getLocalizedDate(rowData.created_at);
  };
  
  return (
    <Layout>
      <Header title={"Invoices"} />
      <Container className="p-2" fluid style={{ backgroundColor: "" }}>
        <Row>
          <Col xs={12} md={8} lg={8}>
            {filteredData &&
              filterdata.length > 0 &&
              filteredData.map((data, index) => {
                const status =
                  data?.paid_amount === data?.grand_total
                    ? "Paid"
                    : data?.paid_amount > 0 &&
                      data?.paid_amount < data?.grand_total
                    ? "Partially Paid"
                    : "Unpaid";
                return (
                  <Card style={{cursor:'pointer'}}
                    key={index}
                    className="mb-2 "
                    onClick={() => {
                      handleRowClick(data);
                    }}
                  >
                    <Card.Header
                      className="d-flex justify-content-between"
                      style={{
                        fontSize: isMobile ? "10px" : "12px",
                        fontWeight: isMobile ? 400 : 400,
                      }}
                    >
                      <div>{data?.voucher_number}</div>
                      <div>{getLocalizedDate(data?.created_at)}</div>
                    </Card.Header>
                    <Card.Body>
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{}}
                      >
                        <Card.Title
                          style={{
                            fontSize: isMobile ? "12px" : "13px",
                            fontWeight: isMobile ? 500 : 500,
                          }}
                        >
                          {" "}
                          {data?.voucher_items[0]?.product_name}
                          {" - "}
                          {data?.voucher_items[0]?.product_code}
                        </Card.Title>
                        <Card.Title
                          style={{
                            fontSize: isMobile ? "12px" : "13px",
                            fontWeight: isMobile ? 500 : 500,
                          }}
                        >
                          <Col>₹ {data?.grand_total}</Col>
                        </Card.Title>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div
                          className="d-flex flex-column justify-content-start "
                          style={{
                            // gap: "20px",
                            marginLeft: "2px",
                            fontSize: isMobile ? "10px" : "12px",
                            fontWeight: isMobile ? 400 : 400,
                          }}
                        >
                          <div>{`Paid Amount `}</div>
                          <div>₹ {data?.paid_amount}</div>
                        </div>
                        <div
                          style={{
                            fontSize: isMobile ? "10px" : "12px",
                            fontWeight: isMobile ? 400 : 400,
                          }}
                        >
                          <Badge
                            bg={
                              status === "Paid"
                                ? "success"
                                : status === "Partially Paid"
                                ? "warning "
                                : "danger"
                            }
                            style={{
                              padding: "5px",
                              borderRadius: "5px",
                              color:
                                status === "Paid"
                                  ? "white"
                                  : status === "Partially Paid"
                                  ? "black"
                                  : "white",
                            }}
                          >
                            {status}
                          </Badge>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                );
              })}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export default InvoicesIndex;
