import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isToggleShow: false,
};

const SharedSlice = createSlice({
  name: "sharedSlice",
  initialState,
  reducers: {
    changeToggleShow(state, action) {
      console.log("menu payload", action.payload);
      state.isToggleShow = action.payload;
    },
  },
});

export default SharedSlice.reducer;

export const { changeToggleShow } = SharedSlice.actions;
