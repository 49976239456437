import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helper/axiosHelper";
import axios from "axios";

const initialState = {
  phone: "",
  otp: "",
  party: "",
  accessToken: "",
  business: {},
  isLoggedIn: false,
  isOtpReceivedStatus: false,
  isOtpVerifiedStatus: false,
  errorStatus: false,
  errorMessage: {},
  otpErrorStatus: false,
  otpErrorMessage: {},
  tenantId: "",
};

export const getOtp = createAsyncThunk("auth/getOtp", async (data) => {
  try {
    console.log("tenant id in get otp", process.env.REACT_APP_X_TENANT);
    const result = {
      phone: data.phone,
      tenant_id: process.env.REACT_APP_X_TENANT,
    };
    const response = await axiosRequest({
      method: "POST",
      url: "auth/subscription-login",
      data: result,
      headers: {
        "Content-Type": "application/json",
        "X-Tenant": data.tenantId,
      },
    });
    console.log("response for stand alone", response);

    return response;
  } catch (error) {
    console.log('error in get otp')
    throw error; // Re-throw the error to be handled by the Redux Toolkit
  }
});

export const otpVerify = createAsyncThunk("auth/otpVerify", async (data) => {
  // const token = process.env.REACT_APP_BEARER_TOKEN
  //console.log('react app secret token',tok)
  try {
    const result = {
      phone: data.phone,
      tenant_id: data.tenantId,
      otp: data.otp,
    };
    const response = await axiosRequest({
      method: "POST",
      url: "auth/subscription-verify",
      data: result,
      headers: {
        "Content-Type": "application/json",
        "X-Tenant": data.tenantId,
      },
    });

    console.log("otp verify responses", response);
    return response;
  } catch (error) {
    throw error; // Re-throw the error to be handled by the Redux Toolkit
  }
});

export const findTenant = createAsyncThunk(
  "auth/findTenant",
  async (domain) => {
    const token = process.env.REACT_APP_BEARER_TOKEN;
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      const response = await axios.get(`${apiUrl}/auth/find-tenant/${domain}`);
      return response.data.tenant_id;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addPhoneNumber: (state, action) => {
      state.phone = action.payload;
    },
    addOtp: (state, action) => {
      state.otp = action.payload;
    },
    setTenantId: (state, action) => {
      console.log("tenantid", action.payload);
      state.tenantId = action.payload;
    },
    resetAuth: (state, action) => { 
    console.log('tenantid', action.payload); 
    state.phone = "";     
    state.otp = "";     
    state.party = "";      
    state.accessToken = "";      
    state.business = {};      
    state.isLoggedIn = false;      
    state.isOtpReceivedStatus = false;     
    state.isOtpVerifiedStatus = false;      
    state.errorStatus = false;      
    state.errorMessage = {};    
  }
  },
  extraReducers(builder) {
    builder
      .addCase(getOtp.fulfilled, (state, action) => {
        state.isOtpReceivedStatus = true;
        state.errorStatus = false
        state.otpErrorStatus = false;
      })
      .addCase(getOtp.rejected, (state, action) => {
        console.log('get otp rejected')
        state.errorStatus = true;
        state.errorMessage = "invalid phone";
      })
      .addCase(otpVerify.fulfilled, (state, action) => {
        console.log("otp verify payload", action.payload);
        state.party = action.payload.party;
        state.accessToken = action.payload?.token;
        state.business = action.payload?.tenant_details;
        state.otpErrorStatus = false;
      
        state.isLoggedIn = true;
      })
      .addCase(otpVerify.rejected, (state, action) => {
        console.log('invalid otp')
        state.otpErrorStatus = true;
        state.otpErrorMessage = "otp verfication field";
      })
      .addCase(findTenant.fulfilled, (state, action) => {
        state.tenantId = action.payload;
      })
      .addCase(findTenant.rejected, (state, action) => {
        state.errorStatus = false;
        state.errorMessage = "Error while get find id. Please try again later.";
      });
  },
});

export default authSlice.reducer;

export const { addPhoneNumber, addOtp, resetAuth, setTenantId } =
  authSlice.actions;
