import React from "react";
import {  Form, Stack } from "react-bootstrap";
import { UilCreditCard } from "@iconscout/react-unicons";

const TotalAmount = ({ state }) => {
  return (
    <Stack
      gap={2}
      className="text-center"
      style={{ borderRight: "1px solid lightgray" }}
    >
      {/* Units sold */}
      <span className="text-black  fw-normal px-3" style={{ fontSize: "12px" }}>
      {`Rs. ${new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(state?.grand_total)} INR`}
       </span>
      <span
        className=" h4"
        style={{ fontSize: "12px", fontWeight: "400", color: "#059f74" }}
      >
        {" "}
        <UilCreditCard color="#059f74" size="16px" className="mx-2" />
        <Form.Label className="mb-0">Total Amount</Form.Label>
      </span>
    </Stack>
  );
};

export default TotalAmount;
