import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { findTenant, setTenantId } from "./modules/login/store/authSlice";
import axios from "axios";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.css';
import Subscription from "./modules/subscription/Subscription";
import HomePage from "./modules/home/HomePage";
import NoPage from "./modules/shared/components/NoPage";
import LoginScreen from "./modules/login/LoginScreen";
import { useSelector } from "react-redux";
import SubscriptionDetails from "./modules/subscription/SubscriptionDetails";
import Invoice from "./modules/subscription/components/Invoice";
import InvoicesIndex from "./modules/subscription/components/InvoicesIndex";
import PaymentIndex from "./modules/subscription/components/PaymentIndex";
import Statements from "./modules/statement/Statement";

function App() {
  

  const{accessToken,isLoggedIn,tenantId}=useSelector((state)=>state.auth)
 
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const domain = queryParameters.get("domain");
  console.log("domain name", domain);
  console.log("tenant id", tenantId);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  console.log("domain name",domain)
  //const tenantId = useSelector(state=>state.auth.tenantId)
  console.log('tenant id',tenantId)

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      dispatch(findTenant(domain));
    };

    fetchData();
  }, [apiUrl, domain]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            accessToken && isLoggedIn ? (
              <HomePage />
            ) : (
              <LoginScreen isMobile={isMobile} />
            )
          }
        />
        <Route
          path="subscription"
          element={<Subscription isMobile={isMobile} />}
        />
        <Route path="home" element={<HomePage isMobile={isMobile} />} />
        <Route path="login" element={<LoginScreen isMobile={isMobile} />} />
        <Route
          path="subscription/details"
          element={<SubscriptionDetails isMobile={isMobile} />}
        />
        <Route
          path="subscription/invoices"
          element={<InvoicesIndex isMobile={isMobile} />}
        />
        <Route
          path="subscription/invoice"
          element={<Invoice isMobile={isMobile} />}
        />
        <Route
          path="subscription/payment"
          element={<PaymentIndex isMobile={isMobile} />}
        />
        <Route
          path="subscription/statement"
          element={<Statements isMobile={isMobile} />}
        />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
