import { useEffect, useState } from "react";
import Header from "../shared/Header";
import Layout from "../shared/components/Layout";
import { Row, Col, Container, Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSubscription } from "./store/subscriptionSlice";
import DataTable from "react-data-table-component";
import GlobalSearch from "./components/fields/GlobalSearch";
import GlobalDropdown from "./components/fields/GloablDropdown";
import { useNavigate } from "react-router-dom";
import { UilFilePlusAlt } from "@iconscout/react-unicons";
import Card from "react-bootstrap/Card";

const Subscription = ({ isMobile }) => {
  const [subscriptionType, setSubScriptionType] = useState("all_subscription");
  const { getSubscriptionData, getSubscriptionStatus } = useSelector(
    (state) => state.subscription
  );

  console.log("getSubscriptionData", getSubscriptionData);
  const { party, accessToken, tenantId } = useSelector((state) => state.auth);

  const [filteredData, setFilteredData] = useState(
    getSubscriptionData ? getSubscriptionData : []
  );

  console.log("filter data", filteredData);

  useEffect(() => {
    if (getSubscriptionData) {
      setFilteredData(getSubscriptionData);
    }
  }, [getSubscriptionData]);

  const handleSubsciptionChange = (e) => {
    setSubScriptionType(e.target.value);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    let partyId = party?.id;
    dispatch(getSubscription({ partyId, accessToken, tenantId }));
  }, []);

  const navigate = useNavigate();
  const handleRowClick = (data) => {
    console.log("handle navigationss", data);
    navigate("/subscription/details", { state: data });
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options);
  };

  return (
    <Layout>
      <Header title={"Memberships"} />
      <Container className=" p-2" fluid>
        <Row>
          <Col xs={12} md={8} lg={8}>
            {filteredData &&
              filteredData.map((data, index) => {

                const status = data.subscription_vouchers[0].voucher.paid_amount

                  ? "Paid"
                  : data?.paid_amount > 0 &&
                    data?.paid_amount < data?.grand_total
                    ? "Partially Paid"
                    : "Unpaid";
                return (
                  <Card
                    style={{ cursor: "pointer" }}
                    key={index}
                    className="mb-2"
                    onClick={() => {
                      handleRowClick(data);
                    }}
                  >
                    {/* mb-3 adds margin bottom to create space */}
                    <Card.Header
                      className="d-flex justify-content-between"
                      style={{
                        fontSize: isMobile ? "10px" : "12px",
                        fontWeight: isMobile ? 400 : 400,
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span className="me-2">{data?.subscription_number}</span>
                        {data?.is_active ? (
                          <Badge bg="success" sty>
                            Active
                          </Badge>
                        ) : (
                          <Badge bg="danger">Deactive</Badge>
                        )}
                      </div>
                      <div>{data?.bill_queue?.start_date ? formatDate(data?.bill_queue?.start_date) : ''}</div>
                    </Card.Header>
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <Card.Title
                          style={{
                            fontSize: isMobile ? "12px" : "13px",
                            fontWeight: isMobile ? 500 : 500,
                          }}
                        >
                          {" "}
                          {data?.product?.name}{" "}
                          {"-"}

                          {data?.subscription_vouchers
                          [0]?.voucher?.voucher_items[0]?.product_name}
                        </Card.Title>
                        <Card.Title
                          style={{
                            fontSize: isMobile ? "12px" : "13px",
                            fontWeight: isMobile ? 500 : 500,
                          }}
                        >
                          <Col>₹ {data?.grand_total}</Col>
                          <div
                            style={{
                              fontSize: isMobile ? "10px" : "12px",
                              fontWeight: isMobile ? 400 : 400,
                            }}
                          >
                            <Badge
                              bg={
                                status === "Paid"
                                  ? "success"
                                  : status === "Partially Paid"
                                    ? "warning "
                                    : "danger"
                              }
                              style={{
                                padding: "5px",
                                borderRadius: "5px",
                                color:
                                  status === "Paid"
                                    ? "white"
                                    : status === "Partially Paid"
                                      ? "black"
                                      : "white",
                                marginTop: '4px',
                              }}

                            >
                              {status}
                            </Badge>
                          </div>

                        </Card.Title>
                      </div>
                      <div className="d-flex justify-content-start align-items-center">
                        <div
                          className={`d-flex flex-column justify-content-start col-md-2`}
                          style={{
                            marginLeft: "2px",
                            fontSize: isMobile ? "10px" : "12px",
                            fontWeight: isMobile ? 400 : 400,
                          }}
                        >
                          <div>{`Plan price`}</div>
                          <div>₹ {data?.subscription_items[0]?.price}</div>
                        </div>

                        {data?.subscription_items[1]?.price && (
                          <div
                            className="d-flex flex-column justify-content-start "
                            style={{
                              marginLeft: isMobile ? "20px" : "",
                              fontSize: isMobile ? "10px" : "12px",
                              fontWeight: isMobile ? 400 : 400,
                            }}
                          >
                            <div>{`Addon prcie `}</div>
                            <div>₹ {data?.subscription_items[1]?.price}</div>
                          </div>
                        )}
                      </div>
                    </Card.Body>
                    <Card.Footer
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        fontSize: isMobile ? "10px" : "12px",
                        fontWeight: isMobile ? 400 : 400,
                      }}
                    >
                      <div>
                        {`Next bill - ${data?.bill_queue?.next_bill_date
                          ? formatDate(data?.bill_queue?.next_bill_date)
                          : ""
                          }`}
                      </div>
                    </Card.Footer>
                  </Card>
                );
              })}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export default Subscription;
