import { useSelector } from "react-redux";



const GlobalCurrency = ({ price }) => {
    const currency = useSelector((state) => state.getSetting?.dataSetting.currency);
    return (
        <>
            {currency} {price ? parseFloat(price).toFixed(2) : "0.00"}
        </>
    )
}

export default GlobalCurrency;