import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Layout from "../shared/components/Layout";
import { Button, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Header from "../shared/Header";
import FontAwesome from "react-fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Statements = ()=>{
    const navigate = useNavigate()
    const handleGoBack = () => {
        navigate(-1);
      };
    return(
        <Layout>
            <Header
          title={"Statements"}
        // itemAction={
        //   <Stack
        //     gap={3}
        //     direction="horizontal"
        //     className="mt-2 mb-2 justify-content-end"
        //   >
        //     <Button
        //      // onClick={handleInvoice}
        //       variant="btn btn-primary"
        //       style={{ fontSize: "12px" }}
        //     >
        //       Statement
        //     </Button>{" "}
        //     <Button
        //       className="d-flex align-items-center "
        //       variant="btn btn-outline-secondary"
        //       onClick={handleGoBack}
        //       style={{ fontSize: "12px" }}
        //     >
        //       <FontAwesomeIcon
        //         className="mx-2"
        //         icon={faChevronLeft}
        //         style={{ cursor: "pointer" }}
        //         fontSize={12}
        //       />
        //       Back
        //     </Button>
        //   </Stack>
        // }
      />
 'this is statement page'
        </Layout>
       
    )

}
export default Statements;