import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { UilPlay } from "@iconscout/react-unicons";
import { useSelector } from "react-redux";

const ActiveSubscription = () => {
  const { getSubscriptionData } = useSelector(
    (state) => state.subscription
  );
  const activeSubscriptions = getSubscriptionData.filter(subscription => subscription.is_active === 1);
  const activeSubscriptionCount = activeSubscriptions.length;
  console.log("active subscription data", activeSubscriptions);
  return (
    <Card
      className=""
      style={{
        backgroundColor: "white",
        boxShadow: "0 0.125rem 0.25rem rgba(6, 9, 23, 0.075)",
        border: "1px solid #dee2e6",
      }}
    >
      <Row>
        <Col lg={12} className="px-4 py-2 align-items-center">
          <UilPlay size="18" color="rgb(97, 199, 235)" />
          <Form.Label className="mx-2" style={{ fontSize: "14px" }}>
            Active Subscription
          </Form.Label>
        </Col>
        <Col lg={12} className="px-4 py-2 align-items-center">
          <Form.Label className="mx-2 mb-0" style={{ fontSize: "14px" }}>
            {activeSubscriptionCount}
          </Form.Label>
        </Col>
      </Row>
    </Card>
  );
};

export default ActiveSubscription;
