import {
  Row,
  Col,
  Button,
  Container,
  InputGroup,
  FormControl,
  Stack,
  Form,
} from "react-bootstrap";
import Header from "../shared/Header";
import Layout from "../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import SubscriptionDetailsTable from "./components/subScriptionDetailsTable";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addExpires, getPrevious } from "../shared/helper/dateConvertion";
import BillingDetails from "./components/BillingDetails";
import TotalAmount from "./components/TotalAmount";
import Addon from "./components/Addon";
import NextBillDate from "./components/NextBillDate";
import BillFrequency from "./components/BillFrequency";
import InvoicesTable from "./components/InvoicesDetailsTable";

const SubscriptionDetails = () => {
  const { state } = useLocation();
  console.log("location state", state);

  const addMonths = addExpires(state?.start_date, state?.expires_after);
  console.log("add months", addMonths);

  const perviousDate = getPrevious(state?.start_date, state?.expires_after);
  console.log("previous date", perviousDate);

  const totalAmount =
    state?.product?.sale_price * state?.subscription_items[0]?.quantity;

  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(false);
  const [showInvoices, setShowInvoices] = useState(false);
  useEffect(() => {
    //  console.log('size lisened')
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleInvoice = () => {
    navigate("/subscription/invoice", { state: [state] });
  };
  
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleInvoiceLabelClick = () => {
    setShowInvoices(true);
  };
  const handleSubscriptionLabelClick = () => {
    setShowInvoices(false);
  };
  return (
    <Layout>
      {/* <SubbscriptionDetaislHeader data={state} /> */}
      <Header
        title={"Manage Membership"}
      />

      <Container fluid className="py-3">
        <div
          className="border  px-2 py-2"
          style={{
            height: "calc(100vh - 80px)",
            overflow: "auto",
            overflowX: "hidden",
            backgroundColor: "white",
          }}
        >
          <Stack
            gap={3}
            direction="horizontal"
            className="mb-2 justify-content-end"
          >
            <Button
              onClick={handleInvoice}
              variant="btn btn-primary"
              style={{ fontSize: "12px" }}
            >
              Invoices
            </Button>{" "}
            <Button
              className="d-flex align-items-center "
              variant="btn btn-outline-secondary"
              onClick={handleGoBack}
              style={{ fontSize: "12px" }}
            >
              <FontAwesomeIcon
                className="mx-2"
                icon={faChevronLeft}
                style={{ cursor: "pointer" }}
                fontSize={12}
              />
              Back
            </Button>
          </Stack>
          <Stack
            direction="horizontal"
            gap={2}
            className="ms-auto border rounded p-4 px-2"
          >
            <TotalAmount state={state} />
            <Addon />
            <NextBillDate state={state} />
            <BillFrequency state={state} />
          </Stack>

          <Row className="mt-3" style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
            <Col xs="auto" className="d-flex align-items-center" style={{ padding: 10 }}>
              <Form.Label
                style={{
                  borderBottom: `2px solid ${!showInvoices ? 'rgb(0, 121, 107)' : 'transparent'}`,
                  fontSize: "12px",                
                  lineHeight: "30px",
                  cursor: "pointer",
                  margin: 0,
                  padding: 0,
                }}
                onClick={handleSubscriptionLabelClick}
              >
                Subscription Details
              </Form.Label>
            </Col>
            <Col xs="auto" className="d-flex align-items-center" style={{ padding: 0 }}>
              <Form.Label
                style={{
                  borderBottom: `2px solid ${showInvoices ? 'rgb(0, 121, 107)' : 'transparent'}`,
                  fontSize: "12px", 
                  lineHeight: "30px",
                  cursor: "pointer",
                  margin: 0,
                  padding: 0,
                  marginLeft: '20px'
                }}
                onClick={handleInvoiceLabelClick}
              >
                Invoices
              </Form.Label>
            </Col>


            <Col lg={12}>
              {showInvoices ? (
                <InvoicesTable data={state} />
              ) : (
                <SubscriptionDetailsTable data={state} />
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
};
export default SubscriptionDetails;
