import React from "react";
import { Col, Row, Card, Form } from "react-bootstrap";
import { UilCalender } from "@iconscout/react-unicons";

const LastPaid = () => {
  return (
    <Card
      className=""
      style={{
        backgroundColor: "white",
        boxShadow: "0 0.125rem 0.25rem rgba(6, 9, 23, 0.075)",
        border: "1px solid #dee2e6",
      }}
    >
      <Row>
        <Col lg={12} className="px-4 py-2 align-items-center">
        <UilCalender size="18" color="#2Ca01c" />
          <Form.Label className="mx-2" style={{fontSize:'14px'}}>Last Paid Date</Form.Label>
        </Col>
        <Col lg={12} className="px-4 py-2 align-items-center">
          {/* <UilRupeeSign size="14" color="blue" /> */}
          <Form.Label className="mx-2 mb-0" style={{fontSize:'14px'}}>23-02-2024</Form.Label>
        </Col>
      </Row>
    </Card>
  );
};

export default LastPaid;
