import { Card, Col, Form, Row } from "react-bootstrap";
import { state } from "../../shared/components/States";

const PartyDetails = ({ data }) => {
  const placeOfSupply =
    state &&
    state.find((value) => value?.id == data?.party?.party_addresses[0]?.state);
  console.log("data", placeOfSupply);
  return (
    <>
      <Row>
        <Col xs={12}>
          <Form.Label className="mb-0" style={{fontSize:'13px',fontWeight:500}}>{data?.party?.name}</Form.Label>
        </Col>
        {data?.party?.party_addresses[0]?.address && (
          <Col xs={12} className="">
            <Form.Label className="mb-0" style={{ fontSize: "12px" }}>
              {" "}
              {data?.party?.party_addresses[0]?.address} ,{" "}
              {data?.party?.party_addresses[0]?.city} , {placeOfSupply.name} -{" "}
              {data?.party?.party_addresses[0]?.pincode}
            </Form.Label>
          </Col>
        )}
        <Col xs={12}>
          <Form.Label className="mb-0" style={{ fontSize: "12px" }}>
            {data?.party?.phone_number}
          </Form.Label>
        </Col>
      </Row>
    </>
  );
};

export default PartyDetails;
