import {  Form, Stack } from "react-bootstrap";
import { UilCalender } from "@iconscout/react-unicons";
import { formateDate } from "../../shared/helper/dateConvertion";

const NextBillDate = ({ state }) => {

  return (
    <Stack
      gap={2}
      className="text-center"
      style={{ borderRight: "1px solid lightgray" }}
    >
      {/* Units sold */}
      <span className="text-black  fw-normal px-3" style={{ fontSize: "12px" }}>
        {new Date(state?.bill_queue?.next_bill_date).toLocaleDateString('en-IN')}
      </span>
      <span
        className=" h4"
        style={{ fontSize: "12px", fontWeight: "400", color: "#FFA500" }}
      >
        {" "}
        <UilCalender color="#FFA500" size="16px" className="mx-2" />
        <Form.Label className="mb-0">Next Billing Date</Form.Label>
      </span>
    </Stack>
  );
};

export default NextBillDate;
