import React from "react";
import { Table, Badge } from "react-bootstrap";
import { getLocalizedDate } from "../../shared/helper/dateUtils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const InvoicesTable = ({ data }) => {

  const navigate = useNavigate();
  const getStatus = (data) => {
    console.log("get amoun", data)
    const paidAmount = data?.subscription_vouchers[0]?.voucher?.paid_amount || 0;
    const grandTotal = data?.grand_total || 0;
    console.log("get amount", grandTotal)
    if (paidAmount === grandTotal) return "Paid";
    if (paidAmount > 0 && paidAmount < grandTotal) return "Partially Paid";
    return "Unpaid";
  };
  const status = getStatus(data);
  const handleRowClick = () => {
    const voucherId = data?.subscription_vouchers?.[0]?.voucher?.id;
    console.log("Extracted Voucher ID:", data);
    if (voucherId) {
      navigate(`/subscription/invoice`, { state: [data] });
    }
  };

  return (
    <div>
      <Table bordered responsive hover>
        <thead>
          <tr>
            <th style={{ fontSize: "12px", fontWeight: "400" }}>Date</th>
            <th style={{ fontSize: "12px", fontWeight: "400" }}>Voucher Number</th>
            <th style={{ fontSize: "12px", fontWeight: "400" }}>Grand Total</th>
            <th style={{ fontSize: "12px", fontWeight: "400" }}>Paid Amount</th>
            <th style={{ fontSize: "12px", fontWeight: "400" }}>Created At</th>
            <th style={{ fontSize: "12px", fontWeight: "400" }}>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr onClick={handleRowClick} style={{ cursor: 'pointer' }}>
            <td style={{ fontSize: "12px" }}>
              {data?.subscription_vouchers?.[0]?.voucher?.created_at
                ? new Date(data.subscription_vouchers[0].voucher.created_at).toLocaleDateString()
                : "N/A"}
            </td>

            <td style={{ fontSize: "12px" }}>
              {data?.subscription_vouchers?.[0]?.voucher?.voucher_number}
            </td>
            <td style={{ fontSize: "12px" }}>
              ₹{data?.grand_total}
            </td>
            <td style={{ fontSize: "12px" }}>
              ₹{data?.subscription_vouchers?.[0]?.voucher?.paid_amount ?? 'N/A'}
            </td>
            <td style={{ fontSize: "12px" }}>
              {getLocalizedDate(data?.created_at)}
            </td>
            <td style={{ fontSize: "12px" }}>
              <Badge
                bg={
                  status == "Paid"
                    ? "success"
                    : status === "Partially Paid"
                      ? "warning "
                      : "danger"
                }
                style={{
                  padding: "5px",
                  borderRadius: "5px",
                  color:
                    status === "Paid"
                      ? "white"
                      : status === "Partially Paid"
                        ? "black"
                        : "white",
                }}
              >
                {status}

              </Badge>

            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default InvoicesTable;
