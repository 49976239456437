import React, { useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";

const GlobalSearch = (props) => {
  const { data, setFilteredData, filteredData } = props;
  console.log("all data", data);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    if (searchString.length == 0) {
      setFilteredData(data);
    } else {
      setFilteredData(filteredData);
    }
  }, [searchString]);

  const handleSearch = (searchParam) => {
    const lowerCaseSearchParam = searchParam.toLowerCase();

    setSearchString(searchParam);
    const filtered = data.filter(
      (datum) =>
        (datum?.subscription_items[0]?.product?.name &&
          datum?.subscription_items[0]?.product?.name
            .toLowerCase()
            .includes(lowerCaseSearchParam)) ||
        (datum?.subscription_items[0]?.product_code &&
          datum?.subscription_items[0]?.product_code
            .toLowerCase()
            .includes(lowerCaseSearchParam))
             //||
        // (datum?.voucher_items[0]?.product_name &&
        //   datum?.voucher_items[0]?.product_name
        //     .toLowerCase()
        //     .includes(lowerCaseSearchParam))
    );

    setFilteredData(filtered);
    console.log("search param", filtered);
  };
  return (
    <FormControl
      type="text"
      id="search"
      name="search"
      placeholder="Search"
      onChange={(e) => {
        handleSearch(e.target.value);
      }}
      value={searchString}
      style={{ marginRight: "10px" }}
    />
  );
};

export default GlobalSearch;
