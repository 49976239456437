import {  Form, Stack } from "react-bootstrap";
import { UilRepeat } from "@iconscout/react-unicons";

const BillFrequency = ({ state }) => {
  return (
       <Stack
       gap={2}
       className="text-center"
      
     >
       {/* Units sold */}
       <span className="text-black  fw-normal px-3" style={{ fontSize: "12px" }}>
       {`${state?.subscription_items[0]?.plan?.interval} ${state?.subscription_items[0]?.plan?.interval_unit}`}
       </span>
       <span className=" h4" style={{ fontSize: "12px", fontWeight: "400",color:'#0099FB' }}>
         {" "}
         <UilRepeat color="#0099FB" size="16px" className="mx-2" />
         <Form.Label className="mb-0">Bill Frequency</Form.Label>
       </span>
     </Stack>
  );
};

export default BillFrequency;
