import { combineReducers } from "redux";
import authSlice from "./modules/login/store/authSlice";
import subscriptionSlice from "./modules/subscription/store/subscriptionSlice";
import sharedSlice from './modules/shared/sharedSlice'

export  const rootReducer = combineReducers({
    auth:authSlice,
    subscription:subscriptionSlice,
    sharedSlice:sharedSlice

})