import { Badge, Card, Col, Form, Row } from "react-bootstrap";
import GlobalCurrency from "../../shared/helper/GlobalCurrency";
const PaidDetails = ({ data }) => {
  console.log('datas', data);
  const status =
    data?.paid_amount === data?.grand_total
      ? "Paid"
      : data?.paid_amount > 0 && data?.paid_amount < data?.grand_total
        ? "Partially Paid"
        : "Unpaid";

  return (
    <Row>
      <Col xs={12} className="px-3">
        <div
          className="d-flex justify-content-between px-2 py-2"
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <div>
            <Form.Label></Form.Label>
          </div>
          <div>
            <Badge
              className=""
              bg={
                status === "Paid"
                  ? "success"
                  : status === "Partially Paid"
                    ? "warning"
                    : "danger"
              }
            >
              {status}
            </Badge>
          </div>
        </div>
      </Col>
      <Row className="py-2 px-4">
        <Col xs={6}>
          <Form.Label className="mb-0" style={{ fontSize: "12px" }}>
            CGST ({data?.subscription_vouchers[0]?.voucher?.voucher_items?.[0]?.tax_rate || '0.00'}%)
          </Form.Label>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <Form.Label className="mb-0" style={{ fontSize: "12px" }}>
            ₹ {data?.subscription_vouchers[0]?.voucher?.cgst_total || '0.00'}
          </Form.Label>
        </Col>
      </Row>
      <Row className="py-2 px-4">
        <Col xs={6}>
          <Form.Label className="mb-0" style={{ fontSize: "12px" }}>
            SGST ({data?.subscription_vouchers[0]?.voucher?.voucher_items?.[0]?.tax_rate || '0.00'}%)
          </Form.Label>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <Form.Label className="mb-0" style={{ fontSize: "12px" }}>
            ₹ {data?.subscription_vouchers[0]?.voucher?.sgst_total || '0.00'}
          </Form.Label>
        </Col>
      </Row>
      <Row className="py-2 px-4">
        <Col xs={6}>
          <Form.Label className="mb-0" style={{ fontSize: "12px" }}>
            Tax
          </Form.Label>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <Form.Label className="mb-0" style={{ fontSize: "12px" }}>
            ₹
            <GlobalCurrency
              price={data?.subscription_vouchers[0]?.voucher?.tax_total || '0.00'}
            />
          </Form.Label>
        </Col>
      </Row>
      <Row className="py-2 px-4">
        <Col xs={6}>
          <Form.Label className="mb-0" style={{ color: "green", fontSize: "16px", fontWeight: 500 }}>
            Total
          </Form.Label>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <Form.Label className="mb-0" style={{ fontSize: "14px" }}>
            ₹ {data?.grand_total || '0.00'}
          </Form.Label>
        </Col>
      </Row>
    </Row>
  );
};

export default PaidDetails;
