import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helper/axiosHelper";

const initialState = {
  getSubscriptionStatus: false,
  getSubscriptionData: [],
  getPaymentStatus: false,
  errorStatus: false,
  errorMessage: {},
  selectId: {},
  voucher: {},
  payment: []
};

export const getSubscription = createAsyncThunk(
  "subscription/getSubscription",
  async (data) => {
   // console.log("react app secret bearerToken  ", bearerToken);
   // const tenantId = process.env.REACT_APP_X_TENANT;
    try {
      const response = await axiosRequest({
        method: "GET",
        url: `subscriptions/party-subscription/${data.partyId}`,
        headers: {
          Authorization: `Bearer ${data.accessToken}`,
          "Content-Type": "application/json",
          "X-Tenant": data.tenantId,
        },
      });
      console.log("subscription response", response.data);

      return response.data;
    } catch (error) {
      throw error; // Re-throw the error to be handled by the Redux Toolkit
    }
  }
);

export const getSubscriptionVoucher = createAsyncThunk(
  "subscription/fetch",
  async (args) => {
    console.log("arguments",args)
  
    try {
      const response = await axiosRequest({
        method: "GET",
        url: `subscription-vouchers/${args.partyId}`,
        headers: {
          Authorization: `Bearer ${args.accessToken}`,
          "X-Tenant": args.tenantId,
        },
      });
      console.log("response data addon", response);
      return response.data;
    } catch (error) {
      console.log("error",error)
      throw new Error("Error submitting subscription");
    }
  }
);

export const getAllSubscriptionVoucher = createAsyncThunk(
  "subscription/fetchAll",
  async (data) => {
  
    try {
      const response = await axiosRequest({
        method: "GET",
        url: `subscription-vouchers`,
        headers: {
          Authorization: `Bearer ${data.accessToken}`,
          "X-Tenant": data.tenantId,
        },
      });
      console.log("response data addon", response);
      return response.data;
    } catch (error) {
      throw new Error("Error submitting subscription");
    }
  }
);

export const getAllPaymentsWithPartyId = createAsyncThunk(
  "subscription/fetchPayment",
  async (data) => {
    try {
      const response = await axiosRequest({
        method: "GET",
        url: `payment-party/${data.partyId}`,
        headers: {
          Authorization: `Bearer ${data.accessToken}`,
          "X-Tenant": data.tenantId,
        },
      });
      console.log("response data", response);
      return response;
    } catch (error) {
      console.error("Error fetching payments:", error);
      throw new Error("Error submitting subscription");
    }
  }
);


const subscriptionSilce = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    resetSubscription: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.getSubscriptionStatus = true;
        state.getSubscriptionData = action.payload;
      })
      .addCase(getSubscription.rejected, (state, action) => {
        state.errorStatus = false;
        state.errorMessage =
          "Error while get subscription by party. Please try again later.";
      })
      .addCase(getSubscriptionVoucher.fulfilled, (state, action) => {
        state.getSubscriptionStatus = true;
        state.selectId = action.payload;
      })
      .addCase(getSubscriptionVoucher.rejected, (state, action) => {
        state.errorStatus = false;
        state.errorMessage =
          "Error while get subscription by party. Please try again later.";
      })
      .addCase(getAllSubscriptionVoucher.fulfilled, (state, action) => {
        state.getSubscriptionStatus = true;
        state.voucher = action.payload;
      })
      .addCase(getAllSubscriptionVoucher.rejected, (state, action) => {
        state.errorStatus = false;
        state.errorMessage =
          "Error while get subscription by party. Please try again later.";
      })
      .addCase(getAllPaymentsWithPartyId.fulfilled, (state, action) => {
        state.getPaymentStatus = true;
        state.payment = action.payload;
      })
      
      .addCase(getAllPaymentsWithPartyId.rejected, (state, action) => {
        state.errorStatus = false;
        state.errorMessage = "Error while get payment by party. Please try again later.";
      });
  },
});

export const { resetSubscription } = subscriptionSilce.actions;

export default subscriptionSilce.reducer;
