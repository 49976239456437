import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { Sidebar, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useNavigate } from "react-router-dom";
import bill from "../../../../assets/images/logo6-small.png";
import { useDispatch, useSelector } from "react-redux";
import { resetAuth } from "../../../login/store/authSlice";
import { Form, InputGroup } from "react-bootstrap";
import "../../components/sidebar.css";
import {
  UilHome,
  UilInvoice,
  UilCreditCard,
  UilSignOutAlt,
} from "@iconscout/react-unicons";
import { useState } from "react";
import LogoutModal from "../Logout";
import { resetSubscription } from "../../../subscription/store/subscriptionSlice";
import SideBarHeader from "./SidebarHeader";

const SideBar = () => {
  const { party } = useSelector((state) => state.auth);
  const isMenuCollapse = useSelector((state) => state.sharedSlice.isToggleShow);
  console.log("is menu collpase", isMenuCollapse);
  console.log("party", party);
  const partyShort = party ? party?.name?.substring(0, 1) : "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location);
  const [show, setShow] = useState(false);

  const handleLogoutModal = () => {
    setShow(!show);
  };

  const handleLogout = () => {
    dispatch(resetAuth());
    dispatch(resetSubscription());
    navigate("/login");
  };
  const MenuStyle = {
    backgroundColor: "white",
    color: "black",
    "&:hover": {
      backgroundColor: "white",
      color: "black",
    },
    "&.active": {
      backgroundColor: "#e9ecef",
      color: "black",
      borderRadius: "10px",
    },
  };

  return (
    <Sidebar
      width="230px"
      //collapsed={isMenuCollapse}
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          backgroundColor: "white",
          height: "100%",
          // paddingLeft: "5px",
          // paddingRight: "5px",
          boxShadow: "3px 5px 5px rgba(6, 9, 23, 0.075)",
        },
      }}
    >
      <SideBarHeader />

      <div
        className="col-lg-12 mt-2"
        style={{
          paddingLeft: "5px",
          paddingRight: "5px",
          height: isMenuCollapse
            ? "calc(100vh - 100px)"
            : "calc(100vh - 150px)",
          overflowY: "auto",
        }}
      >
        <Menu
          className="mt-1"
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              if (level === 0)
                return {
                  // color: disabled ? "white" : "white",
                  backgroundColor: active ? "white" : "white",
                  transition: "0.5s ease",

                  ":hover": {
                    // backgroundColor: "lightgray",
                    // color: "black",
                    // borderLeft: "8px solid #2ca01c",
                    borderRadius: "10px",
                  },
                  fontSize: "13px",
                  whiteSpace: "nowrap",
                  height: "35px",
                  opacity: 1,
                  fontWeight: 400,
                };
            },
          }}
        >
          <MenuItem
            rootStyles={MenuStyle}
            icon={<UilHome size="18" />}
            component={<Link to="/home" />}
          >
            Home
          </MenuItem>
          <MenuItem
            rootStyles={MenuStyle}
            icon={<FontAwesomeIcon icon={faRotate} />}
            component={<Link to="/subscription" />}
          >
            Membership
          </MenuItem>

          <MenuItem
            rootStyles={MenuStyle}
            icon={<UilInvoice size="18" />}
            component={<Link to="/subscription/invoices" />}
          >
            Invoices
          </MenuItem>

          <MenuItem
            rootStyles={MenuStyle}
            icon={<UilCreditCard size="18" />}
            component={<Link to="/subscription/payment" />}
          >
            Payments Made
          </MenuItem>
          {/* <MenuItem
            rootStyles={MenuStyle}
            icon={<UilCreditCard size="18" />}
            component={<Link to="/subscription/statement" />}
          >
            Statement
          </MenuItem> */}
          <MenuItem
            rootStyles={MenuStyle}
            icon={<UilSignOutAlt size="18" color="red" />}
            onClick={() => {
              handleLogoutModal();
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </div>
      <div
        className=" py-2 d-flex justify-content-center"
        style={{
          borderTop: "1px solid lightgray",
        }}
      >
        <img
          src={bill}
          width={isMenuCollapse ? 60 : 90}
          style={
            {
              // display: isMenuCollapse ? "none" : "block",
            }
          }
        />
      </div>
      {show && <LogoutModal onConfirm={handleLogout} onCancel={setShow} />}
    </Sidebar>
  );
};
export default SideBar;
