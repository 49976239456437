import {  Table } from "react-bootstrap";
import { taxList } from "../../shared/helper/taxConfig";

const SubscriptionDetailsTable = ({ data }) => {
  console.log("data table",data)
  const totalAmount =
    data?.product?.sale_price * data?.subscription_items[0]?.quantity;
const TaxLabel = taxList.find((value) => value.code == data?.subscription_items[0]?.tax_code);
const AddonTaxLabel = taxList.find((value) => value.code == data?.subscription_items[1]?.tax_code);
  return (
    // <Container fluid>
    <div>
      <Table bordered responsive hover>
        <thead>
          <tr>
            <th style={{ fontSize: "13px", fontWeight: "400" }}>Item</th>
            <th style={{ fontSize: "13px", fontWeight: "400" }}>Type</th>
            <th style={{ fontSize: "13px", fontWeight: "400" }}>Qty</th>
            <th style={{ fontSize: "13px", fontWeight: "400" }}>Discount</th>
            <th style={{ fontSize: "13px", fontWeight: "400" }}>Rate</th>
            <th style={{ fontSize: "13px", fontWeight: "400" }}>Tax</th>
            <th style={{ fontSize: "13px", fontWeight: "400" }}>Amount</th>
          </tr>
        </thead>
        <tbody>
  <tr>
    <td
      style={{
        fontSize: "12px",
      }}
    >
      {data?.subscription_items[0]?.product?.name || "N/A"}
    </td>
    <td
              style={{
                fontSize: "12px",
              }}
            >
              {data?.subscription_items[0]?.type}
            </td>
            <td
              style={{
                fontSize: "12px",
              }}
            >
              {data?.subscription_items[0]?.quantity}
            </td>
            <td
              style={{
                fontSize: "12px",
              }}
            >
             {data?.discount}
            </td>
            <td
              style={{
                fontSize: "12px",
              }}
            >
              ₹{data?.subscription_items[0]?.price}
            </td>
            <td
              style={{
                fontSize: "12px",
              }}
            > {data?.subscription_items[0]?.tax_code ? TaxLabel.label : "None"}</td>
            <td
              style={{
                fontSize: "12px",
              }}
            >
             {data?.grand_total}
            </td>
  </tr>

  {data?.subscription_items[1]?.product?.name && (
    <tr>
      <td
        style={{
          fontSize: "12px",
        }}
      >
        {data?.subscription_items[1]?.product?.name}
      </td>
      <td
              style={{
                fontSize: "12px",
              }}
            >
              {data?.subscription_items[1]?.type}
            </td>
            <td
              style={{
                fontSize: "12px",
              }}
            >
              {data?.subscription_items[1]?.quantity}
            </td>
            <td
              style={{
                fontSize: "12px",
              }}
            >
             {data?.discount}
            </td>
            <td
              style={{
                fontSize: "12px",
              }}
            >
              {data?.subscription_items[1]?.price}
            </td>
            <td
              style={{
                fontSize: "12px",
              }}
            > {data?.subscription_items[1]?.tax_code ? AddonTaxLabel.label : "None"}</td>
            <td
              style={{
                fontSize: "12px",
              }}
            >
             ₹{data?.grand_total}
            </td>
    </tr>
  )}
        </tbody>
      </Table>
    </div>
    // </Container>
  );
};
export default SubscriptionDetailsTable;
