import React from "react";
import { Col, Row, Card, Form } from "react-bootstrap";
import { UilTransaction } from "@iconscout/react-unicons";
import { useSelector } from "react-redux";

const TotalDue = () => {
  const { getSubscriptionData, voucher } = useSelector(
    (state) => state.subscription
  );
  console.log("total due");
  const { party } = useSelector((state) => state.auth);

  const filterdata =
    voucher &&
    voucher.length > 0 &&
    voucher.filter((value) => value?.party_id === party?.id);
  console.log("filter data", filterdata);
  const dueSubscriptions = getSubscriptionData.filter(
    (subscription) => subscription.paid_amount !== subscription.grand_total
  );
  
 
  
  const totalDue = dueSubscriptions.reduce(
    (sum, subscription) => sum + subscription?.grand_total - Number(subscription?.paid_amount) ,
    0
  );
  console.log("Total due:", totalDue);
  return (
    <Card
      className=""
      style={{
        backgroundColor: "white",
        boxShadow: "0 0.125rem 0.25rem rgba(6, 9, 23, 0.075)",
        border: "1px solid #dee2e6",
      }}
    >
      <Row>
        <Col lg={12} className="px-4 py-2 align-items-center">
          <UilTransaction size="18" color="purple" />
          <Form.Label className="mx-2" style={{ fontSize: "14px" }}>
            Total Due
          </Form.Label>
        </Col>
        <Col lg={12} className="px-4 py-2 align-items-center">
          <Form.Label className="mx-2 mb-0" style={{ fontSize: "14px" }}>
            ₹&nbsp;{totalDue}
          </Form.Label>
        </Col>
      </Row>
    </Card>
  );
};

export default TotalDue;
