import axios from 'axios';



//axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
console.log('backendurl',axios.defaults.baseURL)

export const axiosRequest = async (params) => {
  console.log('axios param',params)
    try {
      params.headers = { ...params.headers };
      const result = await axios.request(params);
      console.log('results',result)
      return result?.data;
     } catch(error) {
     // console.log('erraxios',error)
       let customErr = new Error(error.response.data.message);
       customErr.status  = error.response.status;
       throw customErr;
     } 
 };