import { Card, Col, Container, Form, Row } from "react-bootstrap";
import PartyDetails from "./PartyDetails";
import PaidDetails from "./PaidDetails";

const OrderDetails = ({ data, pdfRef }) => {
  console.log("dat", data);
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options);
  };

  return (
    <Container
      fluid
      className="mt-3"
      style={{
        height: "calc(100vh - 100px)",
        overflow: "scroll",
        overflowX: "hidden",
      }}
    >
      <div ref={pdfRef}>
        <Card
          className="px-4 py-2 mt-3"
          style={{
            // border: "none",
            //boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 1px 4px rgba(0,0,0,0.23)",
            borderRadius: "0px",
          }}
        >
          <div className="d-flex justify-content-between col-12">
            <div>
              <PartyDetails data={data} />
            </div>

            <div>
              <Form.Label
                className="mb-0 form-label"
                style={{ fontSize: "12px", fontWeight: 500 }}
              >
                {"Subscription No"}
              </Form.Label>
              <br />
              <Form.Label style={{ fontSize: "12px" }}>
              {data?.subscription_vouchers?.[0]?.subscription?.subscription_number}
              </Form.Label>
            </div>
          </div>


          <Row className="mt-3 py-3" style={{ borderTop: "1px solid lightgray" }}>
            <Col xs={12} className="d-flex justify-content-between">
              <div>
                <Form.Label
                  className="mb-0"
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  Invoice No
                </Form.Label>
                <br />
                <Form.Label style={{ fontSize: "12px" }}>
                  {data?.subscription_vouchers?.[0]?.voucher?.voucher_number}
                </Form.Label>
              </div>
              <div>
                <Form.Label
                  className="mb-0"
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  Invoice Date
                </Form.Label>
                <br />
                <Form.Label style={{ fontSize: "12px" }}>
                  {formatDate(data?.start_date)}
                </Form.Label>
              </div>
              <div>
                <Form.Label
                  className="mb-0"
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  Next Bill Date
                </Form.Label>
                <br />
                <Form.Label style={{ fontSize: "12px" }}>
                  {formatDate(data?.bill_queue?.next_bill_date)}
                </Form.Label>
              </div>
            </Col>
            <Col
              xs={12}
              className="mt-2 py-1"
              style={{
                borderTop: "1px solid lightgray",
                borderBottom: "1px solid lightgray",
              }}
            >
              <Form.Label className="mb-0" style={{ fontSize: '12px', fontWeight: 500 }}>
                Item Details
              </Form.Label>
            </Col>
            <Row className="mt-2">
              <Col xs={6}>
                <Form.Label className="mb-0" style={{ fontSize: "12px" }}>
                  {data?.subscription_items[0]?.product?.name}
                </Form.Label>
              </Col>
              <Col xs={6} className="d-flex justify-content-end">
                <Form.Label className="mb-0" style={{ fontSize: "12px" }}>
                  ₹{data?.subscription_items[0]?.price} &nbsp; × &nbsp;{" "}
                  {data?.subscription_items[0]?.quantity}
                </Form.Label>
              </Col>
            </Row>
          </Row>
        </Card>
        <Card className="mt-3 p-0" style={{ borderRadius: "0px" }}>
          <PaidDetails data={data} />
          <div className="d-flex mt-3">
            <Col className="px-2" xs={6}
             style={{
              borderTop: "1px solid lightgray", 
            }}>
              <Form.Label
                className="mb-0"
                style={{ fontSize: "13px", fontWeight: 500 }}
              >
                Terms And Conditions
              </Form.Label>
              <br />
              <Form.Label className="mb-0" style={{ fontSize: "12px" }}>
                Goods once sold not return back or exchange
              </Form.Label>
            </Col>
            <Col
              className="px-2"
              xs={6}
              style={{ borderLeft: "1px solid lightgray",borderTop: "1px solid lightgray" }}
            >
              <Form.Label
                className="mb-0"
                style={{ fontSize: "13px", fontWeight: 500 }}
              >
                AUTHORIZED SIGNATORY FOR
              </Form.Label>
              <br />
              <div className="border mb-2" style={{ height: "50px" }}></div>
            </Col>
          </div>
        </Card>
      </div>
    </Container>
  );
};

export default OrderDetails;
