import React, { useEffect, useRef, useState } from "react";
import { Stack, Button, Form, Row, Col } from "react-bootstrap";
import Layout from "../../shared/components/Layout";
import Header from "../../shared/Header";
import "../css/invoice.css";
import InvoiceBody from "./InvoiceBody";
import MobileScreenInvoice from "./mobileScreenInvoice";
import { useLocation, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faDownload,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import OrderDetails from "./OrderDetails";

const Invoice = () => {
const { state } = useLocation();
const data = state;
console.log("Data from state:", state);
  console.log("location state in invoice", state);
  const pdfRef = useRef();
  const componentRef = useRef();
  

  const [isMobile, setIsMobile] = useState(false);
  const [startDownload, setStartDownload] = useState(false);
  console.log("start download", startDownload);

  // console.log('isMobile',isMobile)

  useEffect(() => {
    //  console.log('size lisened')
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);



  const DownloadPdf = () => {
    setStartDownload(true);
    const padding = 6;
    const imgWidth = 210 - 2 * padding;
    const pageHeight = 297 - 2 * padding;

    html2canvas(componentRef.current, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: true,
    }).then((canvas) => {
      const pdf = new jsPDF("p", "mm", "a4", true);
      let position = 0;

      while (position < canvas.height) {
        const remainingHeight = canvas.height - position;
        const pageContentHeight = Math.min(
          remainingHeight,
          canvas.width * (pageHeight / imgWidth)
        );

        const pageCanvas = document.createElement("canvas");
        pageCanvas.width = canvas.width;
        pageCanvas.height = pageContentHeight;

        const ctx = pageCanvas.getContext("2d");
        ctx.drawImage(
          canvas,
          0,
          position,
          canvas.width,
          pageContentHeight,
          0,
          0,
          canvas.width,
          pageContentHeight
        );

        const pageImgData = pageCanvas.toDataURL("image/png", 1.0);
        const pageImgHeight = (pageContentHeight * imgWidth) / canvas.width;

        if (position === 0) {
          pdf.addImage(
            pageImgData,
            "PNG",
            padding,
            padding,
            imgWidth,
            pageImgHeight
          );
        } else {
          pdf.addPage();
          pdf.addImage(
            pageImgData,
            "PNG",
            padding,
            padding,
            imgWidth,
            pageImgHeight
          );
        }

        position += pageContentHeight;
      }

      const timeStamp = Date.now();
      const pdfBlob = pdf.output("blob");
      const blobUrl = URL.createObjectURL(pdfBlob);
      window.open(blobUrl);
    });
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Layout>
      {/* <InVoiceHeader downloadPdf={DownloadPdf}/> */}
      <Header
        title={"Manage Invoices"}
        mobileAction={
          <Row>
            <Col xs={8} className=" px-4">
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{ fontSize: "13px" }}
                onClick={handleGoBack}
              />
              <Form.Label className="mb-0 mx-2" style={{ fontSize: "14px" }}>
              {"#" + data[0]?.subscription_vouchers?.[0]?.voucher?.voucher_number}
              </Form.Label>
            </Col>
            <Col xs={4}>
              <Stack className="float-end mx-2">
                {isMobile && (
                  <Button variant="outline-primary py-1 px-2" onClick={DownloadPdf}>
                    <FontAwesomeIcon
                      className="mx-2"
                      icon={faPrint}
                      style={{ fontSize: "12px" }}
                      data={state}
                    />
                    Print
                  </Button>
                )}
              </Stack>
            </Col>
          </Row>
        }
      />

      {!isMobile ? (
        <InvoiceBody
          pdfRef={componentRef}
          data={state}
          handleGoBack={handleGoBack}
          handlePrint={DownloadPdf}
        />
      ) : (
        <OrderDetails
          pdfRef={componentRef}
          data={state[0]}
          handleGoBack={handleGoBack}
          handlePrint={DownloadPdf}
        />
      )}
    </Layout>
  );
};
export default Invoice;
