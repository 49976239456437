import { useEffect, useState } from "react";
import { Col, Container, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DropDownNavigation from "./components/DropDownNavigation";
import { useDispatch, useSelector } from "react-redux";
import { changeToggleShow } from "./sharedSlice";
import { UilBars } from "@iconscout/react-unicons";
import { UilSetting ,UilSignOutAlt} from "@iconscout/react-unicons";
import LogoutModal from "./components/Logout";
import { resetAuth } from "../login/store/authSlice";
import { resetSubscription } from "../subscription/store/subscriptionSlice";
import bill from "../../assets/images/logo6-small.png";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Header = (props) => {
  const { title, itemAction,data,mobileAction } = props;
  const isMenuCollapse = useSelector((state) => state.sharedSlice.isToggleShow);
  console.log("is menu collpase", isMenuCollapse);

  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  // console.log('isMobile',isMobile)

  useEffect(() => {
    //  console.log('size lisened')
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    const handleLogoutModal = () => {
      setShow(!show);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNavigate = (navigation) => {
    console.log("handle navigation click");
    navigate(navigation);
  };
  const toggle = <Tooltip id="button-tooltip">Toggle Sidebar</Tooltip>;

  const menuClick = () => {
    dispatch(changeToggleShow(true));
  };

  const menuCancel = () => {
    dispatch(changeToggleShow(false));
  };

  const handleLogoutModal = () => {
    setShow(!show);
  };

  const handleLogout = () => {
    dispatch(resetAuth());
    dispatch(resetSubscription());
    navigate("/login");
  };

  return (
    <>
      <Container
        className="px-2"
        fluid
        style={{
          borderBottom: "1px solid lightgray",
          backgroundColor: "white",
        }}
      >
        <div className="d-flex flex-row justify-content-between align-items-center ">
          <div className="d-flex justify-content-between px-0 w-100">
            <div className="d-flex justify-content-start align-items-center p-2  ">
              {isMobile && <DropDownNavigation />}
              {!isMobile && (
                <OverlayTrigger placement="bottom" overlay={toggle}>
                  <div className="">
                    <UilBars
                      onClick={isMenuCollapse ? menuCancel : menuClick}
                      style={{ cursor: "pointer" }}
                      size="18"
                    />
                  </div>
                </OverlayTrigger>
              )}
              {isMobile ? (
                <div style={{ paddingLeft: "10px" }}>
                  <img
                    src={bill}
                    width={isMenuCollapse ? 60 : 90}
                    style={
                      {
                        // display: isMenuCollapse ? "none" : "block",
                      }
                    }
                  />
                </div>
              ) : (
                <Form.Label
                  className="mt-2 mx-3 pt-1"
                  style={{
                    fontSize: "16px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  {title}
                </Form.Label>
              )}
            </div>
           {itemAction} 
          </div>
          {isMobile && (
            <div
              style={{ cursor: "pointer" }}
              data-toggle="tooltip"
              data-placement="top"
              title="log out"
            >
              {/* <UilSignOutAlt
                onClick={() => {
                  handleLogoutModal();
                }}
              /> */}
            </div>
          )}
        </div>

        {show && <LogoutModal onConfirm={handleLogout} onCancel={setShow} />}
      </Container>
      {isMobile && (
        <Col className="px-1 mt-2">
         
          {mobileAction}
        </Col>
      )}
      {/* </Col> */}
    </>
  );
};
export default Header;
